import React from "react";

const Button = ({ buttonText }) => {
  return (
    <h5>
      <span className="text">{buttonText} </span>
      <span className="line -right"></span>
      <span className="line -top"></span>
      <span className="line -left"></span>
      <span className="line -bottom"></span>
    </h5>
  );
};

export default Button;
