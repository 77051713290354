import React from "react";
import { VscChromeClose, VscArrowRight, VscArrowLeft } from "react-icons/vsc";
import whoAmIData from "../data/whoAmIData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

const Modal = ({ setShowModal }) => {
  const HideModal = () => {
    setShowModal(false);
  };

  let largeur = window.innerWidth;
  return (
    <>
      <div className="modal_container" onClick={HideModal}>
        <Swiper
          className="swiper"
          direction={"vertical"}
          loop={true}
          mousewheel={true}
          keyboard={true}
          modules={[Mousewheel, Keyboard]}
        >
          {whoAmIData.map((data, index) => (
            <SwiperSlide
              className="modal"
              id="modal"
              onClick={(e) => e.stopPropagation()}
              key={index}
            >
              <>
                <div className="modal__left">
                  <img
                    className="modal__image "
                    src={data.picture}
                    alt={data.picture}
                  />
                </div>
                <div className="modal__right">
                  <h2 className="modal__title">{data.title}</h2>
                  <p className="modal__text">
                    {largeur < 1700 ? data.mobileText : data.text}
                  </p>
                </div>
              </>
            </SwiperSlide>
          ))}
          <button className="modal__close_button" onClick={HideModal}>
            <span>
              <VscChromeClose />
            </span>
          </button>
          <h6 className="modal__arrow_scroll">
            <VscArrowLeft /> scroll <VscArrowRight />
          </h6>
        </Swiper>
      </div>
    </>
  );
};

export default Modal;
