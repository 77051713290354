import React, { useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Skills from "./Skills";
import WhoAmI from "./WhoAmI";
import Portfolio from "./Portfolio";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Home = () => {
  const titleRef = useRef();
  const location = useLocation();

  const onLoad = (letter_section) => {
    gsap.timeline().fromTo(
      letter_section,
      {
        x: -100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        stagger: 0.33,
        delay: 0.4,
      }
    );
  };

  return (
    <div className="home">
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={<WhoAmI onLoad={onLoad} titleRef={titleRef} />}
          />

          <Route
            path="/skills"
            element={<Skills onLoad={onLoad} titleRef={titleRef} />}
          />

          <Route
            path="/portfolio"
            element={<Portfolio onLoad={onLoad} titleRef={titleRef} />}
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default Home;
