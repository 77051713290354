import React, { useRef, useState } from "react";
import reactDom from "react-dom";
import emailjs from "@emailjs/browser";
import { VscChromeClose } from "react-icons/vsc";

const ContactModal = ({ setShowContactModal }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [hideSubmitButton, setHideSubmitButton] = useState(false);
  const form = useRef();
  const HideModal = () => {
    setShowContactModal(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setHideSubmitButton(true);
    emailjs
      .sendForm(
        "service_rgl7yk2",
        "template_6smutnr",
        form.current,
        "oT4_bnyrlc9ZdR_4j"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailSent(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          setEmailError(true);
        }
      );
  };

  return reactDom.createPortal(
    <div className="modal_container" onClick={HideModal}>
      <div className="contact_modal" onClick={(e) => e.stopPropagation()}>
        <h3>Contactez moi !</h3>

        <form ref={form} className="contact_modal__form" onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Comment vous appellez-vous ?"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Quelle est votre adresse email ?"
            defaultValue={""}
            required
          />
          <textarea
            name="message"
            cols="30"
            rows="10"
            placeholder="Votre message.."
            required
          ></textarea>
          {hideSubmitButton ? (
            <></>
          ) : (
            <input className="submit_button" type="submit" value="Envoyer" />
          )}
        </form>
        <button className="modal__close_button" onClick={HideModal}>
          <span>
            <VscChromeClose />
          </span>
        </button>
        {emailSent ? (
          <div className="contact_modal__submit_message">
            Le message à bien été envoyé 😀
          </div>
        ) : (
          <></>
        )}
        {emailError ? (
          <div className="contact_modal__submit_message_error">
            Oups, il y a un problème ☹️ 😣
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default ContactModal;
