import { useState, useEffect } from "react";
import SkillsModal from "./SkillsModal";
import Button from "./Button";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Skills = ({ onLoad, titleRef }) => {
  const [showSkillsModal, setShowSkillsModal] = useState(false);

  const opacityto1 = (elem, delay, duration) => {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: delay || 0.5,
        duration: duration || 4,
      }
    );
  };
  useEffect(() => {
    opacityto1("#skills_h2", 0.5, 0.7);
  }, []);

  useEffect(() => {
    opacityto1("#button");
  }, []);

  useEffect(() => {
    opacityto1("#skills_h1");
  }, []);

  return (
    <motion.div
      initial={{ scaleX: 0 }}
      animate={{ scaleX: 1 }}
      // exit={{ scaleX: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        className="skills"
        id="skills"
        onMouseEnter={() => onLoad(".letter_skills")}
      >
        <div className="skills__left">
          <div>
            <h1 className="skills__h1" id="skills_h1">
              <span className="skills__blue"> const </span>
              <span className="skills__lightblue">skills</span>
              <span className="skills__white">=</span>
              <span className="skills__white">[]</span>
            </h1>
          </div>
        </div>
        <div className="skills__right">
          <h2 className="title" ref={titleRef} id="skills_h2">
            <span className="letter_skills">S</span>
            <span className="letter_skills">k</span>
            <span className="letter_skills">i</span>
            <span className="letter_skills">l</span>
            <span className="letter_skills">l</span>
            <span className="letter_skills">s</span>
          </h2>
          <div
            className="skills__button"
            id="button"
            onClick={() => setShowSkillsModal(true)}
          >
            <Button buttonText={"Voir mes compétences"} />
          </div>
        </div>
        {showSkillsModal ? (
          <SkillsModal
            showSkillsModal={showSkillsModal}
            setShowSkillsModal={setShowSkillsModal}
          />
        ) : (
          <></>
        )}
      </div>
    </motion.div>
  );
};

export default Skills;
