import React from "react";
import { VscChromeClose, VscArrowRight, VscArrowLeft } from "react-icons/vsc";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard } from "swiper";
import projects from "../data/portfolioData";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";

const PortfolioModal = ({ setShowPortfolioModal }) => {
  const HideModal = () => {
    setShowPortfolioModal(false);
  };
  return (
    <div className="portfolio_modal_container" onClick={HideModal}>
      <Swiper
        className="swiper"
        direction={"vertical"}
        loop={true}
        mousewheel={true}
        keyboard={true}
        modules={[Mousewheel, Keyboard]}
      >
        {projects.map((project, index) => {
          return (
            <SwiperSlide
              className="portfolio_modal"
              onClick={(e) => e.stopPropagation()}
              key={index}
            >
              <>
                <div className="portfolio_modal__left">
                  <h2 className="portfolio_modal__title">
                    {project.title}
                    {console.log(project.title)}
                  </h2>
                  <a target="_blank" href={project.link} rel="noreferrer">
                    visiter le site
                  </a>
                </div>
                <div className="portfolio_modal__right">
                  <img
                    className="portfolio_modal__project_image"
                    src={project.image}
                    alt={project.title}
                  />
                  <h3 className="portfolio_modal__project_type">
                    Type de projet : {project.type}
                  </h3>
                  <p className="portfolio_modal__project_description">
                    {project.description}
                  </p>
                </div>
              </>
            </SwiperSlide>
          );
        })}
        <button className="modal__close_button" onClick={HideModal}>
          <span>
            <VscChromeClose />
          </span>
        </button>
        <h6 className="modal__arrow_scroll">
          <VscArrowLeft /> scroll <VscArrowRight />
        </h6>
      </Swiper>
    </div>
  );
};

export default PortfolioModal;
