import React from "react";
import { VscChromeClose, VscArrowRight, VscArrowLeft } from "react-icons/vsc";
import skillsData from "../data/skillsData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";

const SkillsModal = ({ setShowSkillsModal }) => {
  const HideModal = () => {
    setShowSkillsModal(false);
  };

  return (
    <div className="skills_modal_container" onClick={HideModal}>
      <Swiper
        className="swiper"
        direction={"vertical"}
        loop={true}
        mousewheel={true}
        keyboard={true}
        modules={[Mousewheel, Keyboard]}
      >
        <SwiperSlide
          className="skills_modal"
          id="skills_modal"
          onClick={(e) => e.stopPropagation()}
        >
          <>
            <div className="skills_modal__left">
              <img
                className="skills_modal__left_image"
                src={skillsData[0].image}
                alt="tete"
              />
              <h2 className="skills_modal__title">{skillsData[0].title}</h2>
            </div>

            <div className="skills_modal__right">
              {skillsData[0].skills.map((skill, index) => {
                return (
                  <div className="skills_modal__skill" id={"S" + index}>
                    <img
                      className="skills_modal__skills_image"
                      src={skill.skillImage}
                      alt={skill.skillName}
                    />{" "}
                    <h2>{skill.skillName}</h2>
                  </div>
                );
              })}
            </div>
          </>
        </SwiperSlide>

        <SwiperSlide
          className="skills_modal"
          id="skills_modal"
          onClick={(e) => e.stopPropagation()}
        >
          <>
            <div className="skills_modal__left">
              <img
                className="skills_modal__left_image"
                src={skillsData[1].image}
                alt=""
              />
              <h2 className="skills_modal__title">{skillsData[1].title}</h2>
            </div>

            <div className="skills_modal__softSkills" id="softSkills ">
              {skillsData[1].softSkillsList.map((softSkill, index) => {
                return (
                  <h2 className="skills_modal__skills_h2" key={index}>
                    {" "}
                    {softSkill}
                  </h2>
                );
              })}
            </div>
          </>
        </SwiperSlide>

        <SwiperSlide
          className="skills_modal"
          id="skills_modal"
          onClick={(e) => e.stopPropagation()}
        >
          <>
            <div className="skills_modal__left">
              <img
                className="skills_modal__left_image"
                src={skillsData[2].image}
                alt=""
              />
              <h2 className="skills_modal__title">{skillsData[2].title}</h2>
            </div>

            <div className="skills_modal__right">
              {skillsData[2].images.map((image, index) => {
                return (
                  <div className="skills_modal__skill" id={"S" + index}>
                    <img
                      className="skills_modal__skills_image"
                      src={image}
                      alt={image}
                    />
                  </div>
                );
              })}
            </div>
          </>
        </SwiperSlide>
        <button className="modal__close_button" onClick={HideModal}>
          <span>
            <VscChromeClose />
          </span>
        </button>
        <h6 className="modal__arrow_scroll">
          <VscArrowLeft /> scroll <VscArrowRight />
        </h6>
      </Swiper>
    </div>
  );
};

export default SkillsModal;
