import React, { useState } from "react";
import {
  FaLinkedinIn,
  FaFacebookMessenger,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa";
import ContactModal from "./ContactModal";
const Contact = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  let largeur = window.innerWidth;

  return (
    <div className="contact">
      <div className="contact__links-container">
        <FaEnvelope
          className="contact__link"
          onClick={() => setShowContactModal(true)}
        />
        <a
          href="https://www.linkedin.com/in/patxi-hiribarren/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn className="contact__link" />
        </a>
        <a
          href="https://m.me/patxi.hiribarren"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookMessenger className="contact__link" />
        </a>
        {largeur < 750 ? (
          <a href="tel:+33603532767" target="_blank" rel="noopener noreferrer">
            <FaPhone className="contact__link" />
          </a>
        ) : (
          <></>
        )}
        {showContactModal ? (
          <ContactModal setShowContactModal={setShowContactModal} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Contact;
