import olentzero from "../assets/olentzero500.jpg";
import worldwildquiz from "../assets/worldwildquiz500.jpg";
import lunetic from "../assets/lunetic500.jpg";
import alisma from "../assets/alisma500.jpg";
import decoCouture from "../assets/decocouture500.jpg";
import panda from "../assets/logo_panda_one.png";


const projects = [
  {
    image: olentzero,
    title: "Olentzero music",
    type: "React",
    description:
      "Projet réalisé lors d'un Hachathon de 2 jours , le thème était la musique. Nous avons donc eu l'idée de créer un calendrier de l'avant virtuel et musical! ",
    link: "http://olentzeromusic.patxi.net/",
  },
  {
    image: worldwildquiz,
    title: "World Wild Quiz",
    type: "React",
    description:
      "Projet réalisé en milieu de formation , il avait pour but de nous faire utiliser une API pour la première fois. Nous avons donc choisi une API qui fournit des infos géographiques pour en faire des minis jeux. ! ",
    link: "http://projet2wild.patxi.net/",
  },
  {
    image: lunetic,
    title: "Lunetic",
    type: "React, nodeJS",
    description:
      "Projet fullstack réalisé sur les 2 derniers mois de formation , c'est un projet réel pour le compte de la société Lunetic. C'est une entreprise basée au Pays Basque qui fabrique des lunettes sur-mesure",
    link: "https://lunetic.fr/",
  },
  {
    image: alisma,
    title: "Alisma Informatique",
    type: "Wordpress",
    description:
      "Site internet réalisé pour promouvoir l'activité d'auto-entrepreneur en dépannage informatique que j'ai exercé pendant quelques mois avant ma formation React Node JS.",
    link: "https://alisma-informatique.com/",
  },
  {
    image: decoCouture,
    title: "Déco couture and Co",
    type: "Wordpress-Woocommerce",
    description:
      "Site vitrine pour une couturière d’ameublement avec une petite section e-commerce pour la vente de machines à coudre",
    link: "https://decocoutureandco.com",
  },
  {
    image: panda,
    title: "Panda One",
    type: "Multiples",
    description:
      "En tant que salarié de l'agence Panda One, j'ai réalisé bon nombre de projets et collaboré sur de nombreux autres : Sites vitrines statiques ou CMS, projets spécifiques front ou fullstack avec react, nodejs et mongoDB",
    link: "https://www.panda-one.fr",
  },
];

export default projects;
