import react from "../assets/react.png";
import js from "../assets/JS.png";
import htmlCss from "../assets/htmlcss.png";
import sass from "../assets/sass.svg";
import nodeJs from "../assets/nodejs.svg";
import vsCode from "../assets/VsCode.svg";
import git from "../assets/git.svg";
import gitHub from "../assets/github-2.svg";
import npm from "../assets/npm.svg";
import trello from "../assets/trello.png";
import figma from "../assets/figma.svg";
import windows from "../assets/windows.svg";
import macos from "../assets/macos.svg";
import mysql from "../assets/mysql.svg";
import settings from "../assets/settings.png";
import pencil from "../assets/pencil.png";
import head from "../assets/tete.png";

const skillsData = [
  {
    title: "Hard skills",
    image: settings,
    react: react,
    skills: [
      { skillName: "react", skillImage: react },
      { skillName: "javascript", skillImage: js },
      { skillName: "node JS", skillImage: nodeJs },
      { skillName: "html-css", skillImage: htmlCss },
      { skillName: "sass", skillImage: sass },
      { skillName: "mysql", skillImage: mysql },
    ],
  },
  {
    title: "Soft skills",
    image: head,
    softSkillsList: [
      "Autonomie",
      "Flexibilité",
      "Progression constante",
      "Capacité de prise de décision",
      "Abnégation",
      "Implication",
    ],
  },
  {
    title: "Les outils",
    image: pencil,
    images: [vsCode, git, gitHub, npm, trello, figma, windows, macos],
  },
];

export default skillsData;
