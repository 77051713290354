import React, { useEffect } from "react";
import Logo from "../assets/Logo_Patxi_Hiribarren_dore_500pxV3.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { NavLink } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Header = () => {
  const slideInRight = (elem, delay, duration) => {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        delay: delay || 0.4,
        duration: duration || 0.6,
      }
    );
  };

  useEffect(() => {
    slideInRight("#nav");
  }, []);

  return (
    <div className="header">
      <img className="header__logo" src={Logo} alt="logo patxi" />

      <ul className="header__nav" id="nav">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "header__selected" : "header__navItem"
          }
        >
          <li>{"// Qui suis-je "}</li>
        </NavLink>
        <NavLink
          to="/skills"
          className={({ isActive }) =>
            isActive ? "header__selected" : "header__navItem"
          }
        >
          <li>{"// Skills "}</li>
        </NavLink>
        <NavLink
          to="/portfolio"
          className={({ isActive }) =>
            isActive ? "header__selected" : "header__navItem"
          }
        >
          <li>{"// Portfolio "}</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Header;
