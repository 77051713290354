import patxi from "../assets/Patxi.jpeg";
import seuil from "../assets/DJI_0136.JPG";
import surf from "../assets/P1010059.jpeg";

const whoAmIData = [
  {
    title: "Qui suis-je...",
    text: (
      <p>
        Je m'appel Patxi Hiribarren, <br />
        j'habite à Saint Jean de Luz, je suis marié à une merveilleuse femme qui
        s'appelle Sabine, et je suis l'heureux papa de deux formidables enfants
        : Alice et Martin.
      </p>
    ),
    mobileText: (
      <p>
        Je m'appel Patxi Hiribarren, <br />
        j'habite à Saint Jean de Luz, je suis marié à une merveilleuse femme qui
        s'appelle Sabine, et je suis l'heureux papa de deux formidables enfants
        : Alice et Martin.
      </p>
    ),
    picture: patxi,
  },
  {
    title: "Mon parcours...",
    text: (
      <p>
        {" "}
        Intéressé depuis le début de l'adolescence par l'informatique et les
        nouvelles technologies en général. C'est donc naturellement que mon
        cursus scolaire s'oriente vers des filières technologiques
        (électronique, informatique et réseaux). Après cela, l'envie de nature
        et d'espace ainsi que les opportunités de la vie m'ont dirigé vers une
        filière toute autre qui est l'environnement, plus précisément : les
        travaux du génie écologique. Pendant 15ans, j'y ai donc acquis beaucoup
        de choses. J'y ai appris différents métiers, mais j'ai surtout développé
        mon envie d'apprendre toutes sortes de choses, la faculté d'adaptation
        et la remise en question face aux imprévus. Ces années ont été vraiment
        enrichissantes, cependant au fil des ans et avec l’arrivée de mes
        enfants certaines frictions apparaissent dues à la spécificité du
        métier, de plus l’envie de revenir vers un métier plus proche de mes
        passions se fait sentir… Pour cela, début 2021, je quitte mon emploi et
        m’initie au développement web. Convaincu par mes essais en HTML et CSS,
        je m’inscris et suis à la formation développeur web dispensée par la
        Wild Code School avec passion. Me voilà maintenant développeur Fullstack !!{" "}
      </p>
    ),
    picture: seuil,
    mobileText: (
      <ul>
        <li>➡️ Etudes dans l'électronique et l'informatique</li>

        <li>
          ➡️ 15 années de travail dans l'environnement: Ouvrier du Génie
          écologique puis conducteur de travaux et chargé d'affaires{" "}
        </li>

        <li>➡️ Démission pour reconversion</li>
        <li>➡️ Formation développeur web </li>
        <li>➡️ Développeur web chez Panda-One ! </li>
      </ul>
    ),
  },
  {
    title: "Mes loisirs",
    text: "Mon principal hobbie actuellement : Ma famille ! J’aime les promenades, à vélo ou à pied, les constructions de châteaux, qu’ils soient en sable, Légo ou bois... Depuis toujours, le sport est un de mes principaux centres d'intérêt, en particulier le surf, que je pratique depuis mes 16 ans, malgré mon manque d’assiduité ces dernières années cela me procure toujours autant de plaisir, de bien-être et rebooste toujours ma motivation pour tous le reste. J’ai beaucoup pratiqué aussi le snowboard et le vtt de descente et j’essaye de jouer à la pelote toutes les semaines. La veille technologique est aussi un vrai loisir pour moi, j’aime me tenir informé des nouvelles innovations qu’elles soient matérielles ou logicielles, mes sources préférées sont les articles de presse et les podcasts ! Pour finir, j’aime les séries (plutôt SF et fantastiques), j’adore les livres audio et j’adorerais jouer aux jeux vidéo, seulement, je manque de temps, de connaissances et en plus j’ai toujours été assez médiocre dans le domaine !!!",
    picture: surf,
    mobileText: (
      <ul>
        <li>👨‍👩‍👧‍👦 Ma famille </li>
        <li>🏃 La pratique du sport en général des que j'en ai l'occasion.</li>

        <li>🏄‍♂️ Le surf, le snowboard et la pelote : mes favoris. </li>

        <li>💻 La veille techno. </li>

        <li>🎬 Les series un peu. </li>

        <li>🎮 Les jeux vidéos : j'aimerais bien... </li>
      </ul>
    ),
  },
];

export default whoAmIData;
