import React, { useState, useEffect } from "react";
import Button from "./Button";
import { motion } from "framer-motion";
import PortfolioModal from "./PortfolioModal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Portfolio = ({ onLoad, titleRef }) => {
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);

  const opacityto1 = (elem, delay, duration) => {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: delay || 0.5,
        duration: duration || 4,
      }
    );
  };
  useEffect(() => {
    opacityto1("#portfolio_h2", 0.5, 0.7);
  }, []);

  useEffect(() => {
    opacityto1("#button");
  }, []);

  useEffect(() => {
    opacityto1("#portfolio_h1");
  }, []);

  return (
    <motion.div
      initial={{ scaleX: 0 }}
      animate={{ scaleX: 1 }}
      //exit={{ scaleX: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        className="portfolio"
        id="portfolio"
        onMouseEnter={() => onLoad(".letter_portfolio")}
      >
        <div className="portfolio__left">
          <h2 className="title" ref={titleRef} id="portfolio_h2">
            <span className="letter_portfolio">P</span>
            <span className="letter_portfolio">o</span>
            <span className="letter_portfolio">r</span>
            <span className="letter_portfolio">t</span>
            <span className="letter_portfolio">f</span>
            <span className="letter_portfolio">o</span>
            <span className="letter_portfolio">l</span>
            <span className="letter_portfolio">i</span>
            <span className="letter_portfolio">o</span>
          </h2>
          <div>
            <h1 className="portfolio__h1" id="portfolio_h1">
              <span className="portfolio__blue"> portfolio</span>
              <span className="portfolio__yellow">.map(</span>(
              <span className="portfolio__lightblue">project</span>)
              <span className="portfolio__yellow"> =</span>&#x3009;
              <span className="portfolio__lightblue">project</span>)
            </h1>
          </div>
        </div>
        <div className="portfolio__right">
          <div
            className="portfolio__button"
            id="button"
            onClick={() => setShowPortfolioModal(true)}
          >
            <Button buttonText={"Voir"} />
          </div>
        </div>
        {showPortfolioModal ? (
          <PortfolioModal setShowPortfolioModal={setShowPortfolioModal} />
        ) : (
          <></>
        )}
      </div>
    </motion.div>
  );
};

export default Portfolio;
