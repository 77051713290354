import React, { useState, useEffect } from "react";
import Button from "./Button";
import Modal from "./Modal";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const WhoAmI = ({ titleRef, onLoad }) => {
  const [showModal, setShowModal] = useState(false);

  const opacityto1 = (elem, delay, duration) => {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: delay || 0.5,
        duration: duration || 4,
      }
    );
  };
  useEffect(() => {
    opacityto1("#whoAmI_h2", 0.5, 0.7);
  }, []);

  useEffect(() => {
    opacityto1("#button");
  }, []);

  useEffect(() => {
    opacityto1("#whoAmI_h1");
  }, []);

  return (
    <motion.div
      initial={{ scaleX: 0 }}
      animate={{ scaleX: 1 }}
      //exit={{ scaleX: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        className="whoAmI"
        id="whoAmI"
        onMouseEnter={() => onLoad(".letter_whoAmI")}
      >
        <div className="whoAmI__left" id="whoAmI_left">
          <h2 className="title" ref={titleRef} id="whoAmI_h2">
            <span className="letter_whoAmI">Q</span>
            <span className="letter_whoAmI">u</span>
            <span className="letter_whoAmI">i</span>
            <span className="letter_whoAmI"> </span>
            <span className="letter_whoAmI">s</span>
            <span className="letter_whoAmI">u</span>
            <span className="letter_whoAmI">i</span>
            <span className="letter_whoAmI">s</span>
            <span className="letter_whoAmI">-</span>
            <span className="letter_whoAmI">j</span>
            <span className="letter_whoAmI">e</span>
            <span className="letter_whoAmI"> </span>
            <span className="letter_whoAmI">?</span>
          </h2>
          <div>
            <h1 className="whoAmI__h1" id="whoAmI_h1">
              <span className="whoAmI__blue"> function </span>{" "}
              <span className="whoAmI__yellow"> webDevelopper</span>() &#123;{" "}
              <br />
              &#8199;&#8199;&#8199;
              <span className="whoAmI__lightblue">console</span>.
              <span className="whoAmI__yellow">log</span>(
              <span className="whoAmI__orange">"Bonjour !"</span>); <br />{" "}
              &#125;
            </h1>
          </div>
        </div>
        <div className="whoAmI__right" id="whoAmI_right">
          <div onClick={() => setShowModal(true)} id="button">
            <Button buttonText={"En savoir plus"} />
          </div>
        </div>
        {showModal ? (
          <Modal showModal={showModal} setShowModal={setShowModal} />
        ) : (
          <></>
        )}
      </div>
    </motion.div>
  );
};

export default WhoAmI;
